import { gql, useMutation } from "@apollo/client";

const useLogin = () => {
  const MUTATE = gql`
    mutation Login($email: String!, $password: String!) {
      loginEmployee(input: { identifier: $email, password: $password }) {
        jwt
        user {
          email
          confirmed
          blocked
        }
      }
    }
  `;
  const [setData, { data, loading, error }] = useMutation(MUTATE);

  return [data, loading, error, setData];
};

export default useLogin;
