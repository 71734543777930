import React, { useState, useEffect, useContext } from "react";

import { Button } from "@paljs/ui/Button";
import { InputGroup } from "@paljs/ui/Input";
import { Checkbox } from "@paljs/ui/Checkbox";
import { Link, navigate } from "gatsby";
import Layout from "../components/Layouts";
import Auth, { Group } from "../components/Auth";
import Seo from "../components/SEO";
import { useLogin } from "../mutations";
import produce from "immer";
import { UserAuthContext } from "../context";
import _ from "lodash";
import { useAlert } from "react-alert";
import Spinner from "@paljs/ui/Spinner";

const Login = ({ location }) => {
  const [loginData, loginLoading, loginError, setLoginVariable] = useLogin();
  const { isAuthenticated, setUser } = useContext(UserAuthContext);
  const alert = useAlert();
  const { state: routeState } = location;
  const redirect = !routeState
    ? `/${process.env.GATSBY_ADMIN_URL}`
    : !routeState.redirect
    ? `/${process.env.GATSBY_ADMIN_URL}`
    : `${routeState.redirect}`;

  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (!isAuthenticated) return;

    navigate(redirect);
  }, [isAuthenticated, redirect]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: true,
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDisable(true);
    if (isAuthenticated) return;

    setLoginVariable({
      variables: {
        email: formData.email,
        password: formData.password,
      },
    });
  };

  useEffect(() => {
    if (_.isEmpty(loginError)) return;

    alert.error("Email or Password is invalid");
    setDisable(false);
  }, [loginError, alert]);

  useEffect(() => {
    if (_.isEmpty(loginData)) return;
    const accessToken = loginData.loginEmployee.jwt;
    if (_.isEmpty(accessToken)) return;

    setUser({
      email: formData.email,
      accessToken: accessToken,
      rememberMe: formData.rememberMe,
      id: loginData.loginEmployee.user.id,
      confirmed: loginData.loginEmployee.user.confirmed,
    });
  }, [loginData, formData, setUser]);

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      })
    );
  };

  return (
    <Layout>
      <Auth title="Login" subTitle="Hello! Login with your email">
        {!_.isEmpty(process.env.GATSBY_ENV) && (
          <div className="h1 text-danger text-center pt-3 pb-5">
            &gt;&gt;&gt;&gt;&gt; {_.upperCase(process.env.GATSBY_ENV)}{" "}
            &lt;&lt;&lt;&lt;&lt;
          </div>
        )}

        <Seo title="Login" />

        <form onSubmit={onSubmit}>
          <InputGroup fullWidth>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              required
              onChange={onInputChange}
              value={formData.email}
            />
          </InputGroup>
          <InputGroup fullWidth>
            <input
              type="password"
              name="password"
              placeholder="Password"
              required
              onChange={onInputChange}
              value={formData.password}
            />
          </InputGroup>
          <Group>
            <Checkbox
              name="rememberMe"
              onChange={(value) =>
                onInputChange({
                  target: {
                    type: "checkbox",
                    name: "rememberMe",
                    checked: value,
                  },
                })
              }
              checked={formData.rememberMe}
            >
              Remember me
            </Checkbox>
            <Link to="/request-password">Forgot Password?</Link>
          </Group>
          <Button
            className="round-btn"
            status="Success"
            shape="SemiRound"
            fullWidth
            type="submit"
            disabled={disable}
          >
            Login
            {disable && <Spinner status="Success" />}
          </Button>
        </form>
      </Auth>
    </Layout>
  );
};

export default Login;
